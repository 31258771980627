import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeComponent } from './home/home.component';
import { EnvelopeEditorComponent } from './envelope-editor/envelope-editor.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FormBuilderComponent } from './envelope-editor/form-builder/form-builder.component';
import {MaterialModule} from './material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderService} from './loader/loader.service';
import {PmsLoaderInterceptor} from './pms-loader.interceptor';
import {LoaderComponent} from './loader/loader.component';
import {EnvelopeListComponent} from './envelope-list/envelope-list.component';
import {DocusignMappingComponent} from './docusign-mapping/docusign-mapping.component';
import {TreeBuilderComponent} from './docusign-mapping/tree-builder/tree-builder.component';
import {AlertBoxComponent} from './alert-box/alert-box.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EnvelopeEditorComponent,
    FormBuilderComponent,
    LoaderComponent,
    EnvelopeListComponent,
    DocusignMappingComponent,
    TreeBuilderComponent,
    AlertBoxComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    LayoutModule,
    HttpClientModule
  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PmsLoaderInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
