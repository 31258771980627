import { Component, OnInit } from '@angular/core';
import {EnvelopeEditorService} from '../envelope-editor/envelope-editor.service';

@Component({
  selector: 'app-envelope-list',
  templateUrl: './envelope-list.component.html',
  styleUrls: ['./envelope-list.component.scss']
})
export class EnvelopeListComponent implements OnInit {
  projectId = '';
  submitted = false;
  pageNo = 1;
  pageSize = 10;
  showPopup = false;
  pageSizeOptions: number[] = [10, 20, 30];
  statusMessage = {
    status: 'default',
    msg: ''
  };

  constructor(
      public envService: EnvelopeEditorService
  ) { }

  ngOnInit() {
    this.cleanForm();
  }
  projectIdChange() {
    this.submitted = false;
    this.cleanForm();
  }
  onShowDetails() {
    if (this.projectId) {
      this.envService.getEnvelopeList(this.projectId, this.pageNo, this.pageSize).subscribe((res: any) => {
        this.submitted = true;
        if (res && res.data) {
          this.envService.envListData = res.data;
          // console.log(res.data);
        }
      });
    }
  }
  cleanForm() {
    if (this.envService.envListData) {
      this.submitted = false;
      this.envService.envListData = {};
      this.clearMsg();
    }
  }
  changePage(event) {
    // console.log(event)
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.pageNo = 1;
    } else {
      this.pageNo = event.pageIndex + 1;
    }
    this.clearMsg();
    this.onShowDetails();
  }
  clearMsg() {
    this.statusMessage = {
      status: 'default',
      msg: ''
    };
  }
  onVoidClick(ev) {
    this.clearMsg();
    this.envService.voidEnvelope(ev.envelope_id).subscribe((res: any) => {
      if (res.success) {
        this.onShowDetails();
        this.statusMessage.msg = 'Success! Contract has been voided.';
        this.statusMessage.status = 'success';
        this.showPopup = true;
      } else {
        this.statusMessage.msg = 'Sorry! Something went wrong while voiding the envelope.';
        this.statusMessage.status = 'error';
        this.showPopup = true;
      }
      // console.log(res);
    });
  }

  dismissFn(w) {
    this.showPopup = w;
  }
}
