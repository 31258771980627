import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tree-builder',
  templateUrl: './tree-builder.component.html',
  styleUrls: ['./tree-builder.component.scss']
})
export class TreeBuilderComponent implements OnInit {
  @Input() data: any;
  @Input() type: any;
  @Input() timeline: any;

  constructor() { }

  ngOnInit() {
  }

  isObject(data): boolean {
    return typeof data === 'object' && data !== null;
  }

  objKeys(obj) {
    return Object.keys(obj);
  }
  onDrag(event, key) {
    event.target.style.cursor = 'dragging';
    event.dataTransfer.setData('key', key);
  }
}
