import {Component, OnInit} from '@angular/core';
import {EnvelopeEditorService} from '../envelope-editor/envelope-editor.service';

@Component({
  selector: 'app-docusign-mapping',
  templateUrl: './docusign-mapping.component.html',
  styleUrls: ['./docusign-mapping.component.scss']
})
export class DocusignMappingComponent implements OnInit {
  data = {};
  activeTabs = [];

  constructor(
      public evService: EnvelopeEditorService
  ) { }

  ngOnInit() {
    this.evService.getDocuSignTabs().subscribe((r: any) => {
      if (r && r.data) {
        r.data.map((rs) => {
          this.activeTabs.push({
            label: rs.tab_label,
            custom_field: ''
          });
        });
      }
    });
    this.data = {
      projectId: null,
      solarData: {
        address: {
          zip: null,
          city: null,
          info: null,
          state: null,
          status: null,
          street: null,
          country: null,
          google_place_id: null
        },
        utility: {
          status: null,
          selected: null,
          costFromCustomer: null
        },
        variant: null,
        zipcode: null,
        property: {
          type: {
            apartment: null,
            commercial: null,
            singleFamily: null
          },
          status: null,
          ownership: {
            own: null,
            rent: null
          }
        },
        userinfo: {
          email: null,
          status: null,
          fullname: null,
          autologin: null,
          phoneNumber: null,
          utilityAccountNumber: null
        },
        csResponse: null,
        current_stage: null,
        projectStatus: null,
        communitySolar: {
          status: null,
          isEligible: null,
          csPipedriveWaitinglist: null,
          csPipedriveProjectTypeStored: null
        },
        csCallSchedule: {
          callMeNow: null
        },
        pipeDriveStatus: {
          pipeDriveSwitched: null,
          pipeDriveFlowCallCompleted: null
        },
        isCommunitySolar: null,
        projectStatusName: null,
        communitySolarPrograms: {
          status: null,
          selectProgram: null,
          selectedProgram: null
        },
        communitySolarEnrollment: {
          terms: null,
          status: null,
          cs_project: {
            id: null,
            logo: null,
            is_active: null,
            project_name: null,
            farm_location: null,
            provider_name: null
          },
          capacity_MW: null,
          viewProgram: null,
          solarEnvData: {
            carMiles: null,
            co2Avoided: null,
            treesPlanted: null
          },
          cancellationFee: null,
          lifetimeSavings: null,
          confirmationStage: null,
          capacity_MW_filled: null
        }
      }
    };
  }

  onDrop(event, tab: any) {
    event.preventDefault();
    tab.custom_field = event.dataTransfer.getData('key');
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
}
