import { Component, OnInit } from '@angular/core';
import {EnvelopeEditorService} from './envelope-editor.service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-envelope-editor',
  templateUrl: './envelope-editor.component.html',
  styleUrls: ['./envelope-editor.component.scss']
})
export class EnvelopeEditorComponent implements OnInit {
  projectId = '';
  submitted = false;
  envelopeKeys = [];
  createdDate = '';
  updatedDate = '';
  statusMessage = {
    status: 'default',
    msg: ''
  };
  showPopup = false;
  constructor(
      public envService: EnvelopeEditorService,
      private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.projectId = params.projectId;
      this.onShowDetails();
    });
  }
  projectIdChange() {
    this.submitted = false;
    this.cleanForm();
  }
  onShowDetails() {
    if (this.projectId) {
      this.envService.getEnvelope(this.projectId).subscribe((res: any) => {
        this.submitted = true;
        if (res && res.data) {
          this.envService.engineData = this.removeCreatedUpdated(res.data);
          this.envelopeKeys = Object.keys(res.data);
        } else {
          this.envelopeKeys = [];
        }
      }, (err) => {
        this.submitted = true;
        this.cleanForm();
      });
      this.envelopeKeys = Object.keys(this.envService.engineData);
    }
  }
  removeCreatedUpdated(data) {
    // this.createdDate = data.updated_at ? moment(data.updated_at).fromNow() : '';
    this.updatedDate = data.updated_at ? moment(data.updated_at).fromNow() : '';
    this.envService.getEnvelopeList(this.projectId, 1, 1).subscribe((r: any) => {
      if (r && r.data && r.data.data.length > 0) {
        let date = r.data.data[0].updated_at.replace(' ', 'T');
        date = date.indexOf('Z') < 0 ? date + 'Z' : date;
        this.createdDate = date ? moment(date).fromNow() : '';
        // console.log(res.data);
      }
    });
    if (data.created_at) {
      delete data.created_at;
    }
    if (data.updated_at) {
      delete data.updated_at;
    }
    if (data.routing_order) {
      this.envService.routingOrder = data.routing_order;
      delete data.routing_order;
    }
    return data;
  }
  dismissFn(w) {
    this.showPopup = w;
  }
  cleanForm() {
    if (this.envService.engineData && this.envelopeKeys.length > 0) {
      this.envService.engineData = {};
      this.envelopeKeys = [];
      this.statusMessage = {
        status: 'default',
        msg: ''
      };
    }
  }
  onSaveClick() {
    if (this.projectId) {
      this.envService.saveEnvelope(this.projectId).subscribe((res: any) => {
        this.envService.engineData = this.removeCreatedUpdated(res.data);
        this.statusMessage.msg = 'Success! Changes have been saved successfully.';
        this.statusMessage.status = 'success';
        this.showPopup = true;

      }, (err) => {
        this.statusMessage.msg = 'Sorry! Something went wrong while saving the envelope.';
        this.statusMessage.status = 'error';
        this.showPopup = true;

      });
    }
  }
  onSaveAndReGenClick() {
    if (this.projectId) {
        this.envService.reGenEnvelope(this.projectId).subscribe((res: any) => {
          this.onShowDetails();
          this.statusMessage.msg = 'Success! Contract has been re-generated.';
          this.statusMessage.status = 'success';
          this.showPopup = true;

        }, (err) => {
          this.statusMessage.msg = 'Sorry! Something went wrong while re-generating the contract.';
          this.statusMessage.status = 'error';
          this.showPopup = true;

        });
    }
  }
}
