import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ENGINE_URL} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class EnvelopeEditorService {
  engineData: any;
  routingOrder: any;
  envListData: any = {};

  constructor(
      public http: HttpClient,
      public router: Router
  ) { }
  getEnvelope(projectId) {
    return this.http
        .get(ENGINE_URL + `docusign/project/${projectId}/tabs/data`, {});
  }
  getDocuSignTabs() {
    return this.http
        .get(ENGINE_URL + `docusign/account/tabs`, {});
  }
  getEnvelopeList(projectId, pageNo, pageSize) {
    return this.http
        .get(ENGINE_URL + `docusign/project/${projectId}/envelopes?page=${pageNo}&perPage=${pageSize}`, {});
  }
  voidEnvelope(envelopeId) {
    return this.http
        .get(ENGINE_URL + `docusign/envelopes/${envelopeId}/void`, {});
  }
  saveEnvelope(projectId) {
    // const body = '{"mapped_customer_values":{"Customer_Phone":"(455) 544-4542","Customer_1_Name":"test_uesu@jitu.com","Customer_1_Email":"prince@pickmysolar.com","Customer_Address":"407 S Roxbury Dr, Beverly Hills, CA 90212, USA","Is_Fixed_Rate_50":"true","Is_Fixed_Rate_100":"false","Subscription_Fee_50":6,"Subscription_Fee_100":null,"Subscription_Rate_50":null,"Subscription_Rate_100":null,"CS_Utility_Account_No.":122111,"Subscription_Fee_50_Percent":5},"mapped_recipient_values":{"CC":{"name":"Prince","email":"prince@pickmysolar.com","clientUserId":null},"Dummy":{"name":"Jitendra","email":"jitendra@pickmysolar.com","clientUserId":null},"Owner 1":{"name":"test_uesu@jitu.com","email":"prince@pickmysolar.com","clientUserId":1567149066},"Owner 2":{"name":"Prasentjit","email":"prasenjit@pickmysolar.com","clientUserId":null},"Installer":{"name":"Antonio Perez","email":"siban@pickmysolar.com","clientUserId":null}}}';
    const data = JSON.parse(JSON.stringify(this.engineData));
    if (this.routingOrder) {
      data.routing_order = this.routingOrder;
    }
    const body = JSON.stringify(data);
    const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    return this.http
        .put(ENGINE_URL + `docusign/project/${projectId}/tabs/data`, body, { headers });
  }
  reGenEnvelope(projectId) {
    const data = JSON.parse(JSON.stringify(this.engineData));
    if (this.routingOrder) {
      data.routing_order = this.routingOrder;
    }
    const body = JSON.stringify(data);
    const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    return this.http
        .post(ENGINE_URL + `docusign/project/${projectId}/edited/envelope/send`, body, { headers });
  }
}
