import {Component, Input, OnInit, Output} from '@angular/core';
import {EnvelopeEditorService} from '../envelope-editor.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  @Input() data: any;
  @Input() previousKey: any;

  constructor(
      public envService: EnvelopeEditorService
  ) { }

  ngOnInit() {
    // console.log(this.data);
  }
  objKeys(obj) {
    return Object.keys(obj);
  }
  isObject(data): boolean {
    return typeof data === 'object' && data !== null;
  }
  replaceUnderscore(val: string): string {
    return val.replace(/_/gi, ' ');
  }
 /* onUp(key, replacementKey) {
    this.envService.engineData.mapped_recipient_values = this.reArrangeObj(this.data, key, replacementKey, 'before');
  }
  onDown(key, replacementKey) {
    this.envService.engineData.mapped_recipient_values = this.reArrangeObj(this.data, key, replacementKey, 'after');
  }
  reArrangeObj(data, key, replacementKey, order) {
    const mainData = data[key];
    const replaceData = data[replacementKey];
    const upperData = {};
    const lowerData = {};
    delete data[key];
    const keys = Object.keys(data);
    keys.map((r, index) => {
      if (index < keys.indexOf(replacementKey)) {
        lowerData[r] = data[r];
      } else if (index > keys.indexOf(replacementKey)) {
        upperData[r] = data[r];
      }
    });
    if (order === 'before') {
      data = {...lowerData, [key]: mainData, [replacementKey]: replaceData, ...upperData};
    } else {
      data = {...lowerData, [replacementKey]: replaceData, [key]: mainData, ...upperData};
    }
    return data;
  }*/
}
