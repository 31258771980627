import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {EnvelopeEditorComponent} from './envelope-editor/envelope-editor.component';
import {EnvelopeListComponent} from './envelope-list/envelope-list.component';
import {DocusignMappingComponent} from './docusign-mapping/docusign-mapping.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'envelope-editor',
    component: EnvelopeEditorComponent
  },
  {
    path: 'envelope-editor/:projectId/edit',
    component: EnvelopeEditorComponent
  },
  {
    path: 'envelope-list',
    component: EnvelopeListComponent
  },
  {
    path: 'mapping',
    component: DocusignMappingComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
