import {AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit, AfterViewInit {
  @Input() type: any;
  @Input() msg: any;
  @Output() dismiss = new EventEmitter();


  width: Promise<any>;
  height: Promise<any>;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSize();
  }

  constructor(public componentRef: ElementRef) {

  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.setSize();
    console.log(this.componentRef.nativeElement.parentNode.offsetHeight);
  }
  setSize() {
    this.height = Promise.resolve(this.componentRef.nativeElement.parentNode.offsetHeight + 'px');
    this.width = Promise.resolve(this.componentRef.nativeElement.parentNode.offsetWidth + 'px');
  }

}
